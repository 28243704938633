import * as React from "react"
import PropTypes from "prop-types"

import AdminSystemAdminHeader from "./adminSystemAdminHeader"
import Footer from "../../ui/footer"

const AdminSystemAdminLayout = ({
  user,
  isUserAut,
  page,
  kitchenSettings,
  dispatchKitchenSettings,
  dispatchFeedback,
  children,
  footerOne,
  footerTwo,
  footerTwoLarge,
  footerTwoWhiteBG,
}) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <AdminSystemAdminHeader
        user={user}
        isUserAut={isUserAut}
        page={page}
        dispatchFeedback={dispatchFeedback}
        kitchenSettings={kitchenSettings}
      >
        <div
          style={{
            marginTop: "0px",
          }}
        >
          {children}
        </div>

        <Footer
          footerOne={footerOne}
          footerTwo={footerTwo}
          footerTwoLarge={footerTwoLarge}
          footerTwoWhiteBG={footerTwoWhiteBG}
        />
      </AdminSystemAdminHeader>
    </div>
  )
}

AdminSystemAdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminSystemAdminLayout
