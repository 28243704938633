import * as React from "react"

import Grid from "@material-ui/core/Grid"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import CloseIcon from "@mui/icons-material/Close"
import getTimeAgo from "../helperFunc/getTimeAgo"

import OrderDetailsCont from "./orderDetailsCont"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  dialogTitCont: {
    padding: "1rem",
    backgroundColor: theme.palette.common.black,
  },
  dialogContent: {
    textAlign: "left",
    minHeight: "5rem",
    backgroundColor: theme.palette.common.blackThree,
    padding: "1rem",
  },

  taxInvTit: {
    fontSize: "1.5rem",
  },

  butText: {
    fontSize: "1.2rem",
  },

  but: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  dialogTitSubText: {
    fontWeight: 300,
    fontSize: "1.2rem",
  },

  xBut: {
    backgroundColor: theme.palette.common.blackThree,
    color: theme.palette.common.white,
  },
  circularProgress: {
    verticalAlign: "middle",
  },
  butGrid: {
    padding: "0.75rem",
  },
}))

const OrderDialogCont = ({
  orderItem,
  setOpen,
  user,
  dispatchFeedback,
  ordersList,
  setOrdersList,
  index,
}) => {
  const classes = useStyles()

  return (
    <>
      <DialogTitle
        id="order-Dialog-title"
        classes={{ root: classes.dialogTitCont }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" align="left">
              {`Order #${orderItem.id}`}
            </Typography>
            <Typography
              variant="body1"
              align="left"
              classes={{ root: classes.dialogTitSubText }}
            >
              {`${getTimeAgo(orderItem.attributes.orderTime, true)}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                setOpen(false)
              }}
              aria-label="close"
              color="primary"
              classes={{
                root: classes.xBut,
              }}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <OrderDetailsCont
          orderItem={orderItem}
          user={user}
          dispatchFeedback={dispatchFeedback}
          ordersList={ordersList}
          setOrdersList={setOrdersList}
          index={index}
        />
      </DialogContent>
    </>
  )
}

export default OrderDialogCont
