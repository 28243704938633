import * as React from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import Dialog from "@material-ui/core/Dialog"

import FlatwareOutlinedIcon from "@mui/icons-material/FlatwareOutlined"
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined"
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined"
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined"
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined"
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined"

import getHrMinText from "../helperFunc/getHrMinText"
import getTimeAgo from "../helperFunc/getTimeAgo"
import priceFormat from "../../../helperFunc/priceFormat"
import OrderDialogCont from "./orderDialogCont"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderItemBox: {
    marginBottom: "0.75rem",
    borderRadius: "0.75rem",
    padding: 0,
    textAlign: "left",
    textTransform: "none",
    backgroundColor: theme.palette.common.blackTwo,
    "&:hover": {
      backgroundColor: `${theme.palette.common.blackFour} !important`,
    },
  },
  itemGrid: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },

  popper: {
    margin: "0 0.35rem !important",
  },

  greyText: {
    color: theme.palette.common.blackNine,
    lineHeight: "1rem",
  },

  ordTypeAva: {
    width: "2rem !important",
    height: "2rem !important",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
  },

  ordStatus: {
    width: "2rem !important",
    height: "2rem !important",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
  },

  dineInAva: {
    backgroundColor: theme.palette.common.dineIn,
  },
  takeAwayAva: { backgroundColor: theme.palette.common.blackFive },

  failedAva: {
    backgroundColor: theme.palette.common.red,
  },
  receivedAva: {
    backgroundColor: theme.palette.common.received,
  },
  preparingAva: {
    backgroundColor: theme.palette.common.preparing,
  },
  readyAva: {
    backgroundColor: theme.palette.common.ready,
  },
  doneAva: {
    backgroundColor: theme.palette.common.done,
  },

  statusBox: {
    paddingTop: "0.15rem",
    paddingBottom: "0.15rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },

  typeBox: {
    paddingTop: "0.15rem",
    paddingBottom: "0.15rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  timeBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },
  chipTime: {
    height: "1.75rem",
    backgroundColor: theme.palette.common.black,
  },
  chipPrice: {
    height: "1.75rem",
    backgroundColor: theme.palette.common.blackThree,
  },
  stateText: {
    lineHeight: "1rem",
  },

  iconSize: {
    fontSize: "1.25rem !important",
  },

  iconSizeSucess: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.green,
  },

  iconSizeFail: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.red,
  },
}))

const OrdersMainInCont = ({
  index,
  orderItem,
  user,
  dispatchFeedback,
  kitchenSettings,
  storeSettings,
  ordersList,
  setOrdersList,
  location,
}) => {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()
  const matchesSM = useMediaQuery(theme => theme.breakpoints.down("sm"))

  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    window.onpopstate = e => {
      if (open) {
        handleClose()
        navigate("/orders/admin")
        return
      }
    }
  }, [open])

  return (
    <>
      <Box
        classes={{ root: classes.orderItemBox }}
        component={Button}
        onClick={e => {
          setOpen(true)
        }}
        fullWidth
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          classes={{ root: classes.itemGrid }}
        >
          <Grid item xs={5} sm={5} md={4}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h6">{`#${orderItem.id}`}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.greyText }}
                >{`${orderItem.attributes.users_permissions_user.data.attributes.userDisplayName}`}</Typography>
                <Typography
                  variant="body1"
                  classes={{ root: classes.greyText }}
                >{`${orderItem.attributes.users_permissions_user.data.attributes.phone}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={2} md={4}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={5}>
                <Box classes={{ root: classes.statusBox }}>
                  <Avatar
                    classes={{
                      root: clsx(classes.ordStatus, {
                        [classes.failedAva]:
                          orderItem.attributes.status === "Failed",
                        [classes.receivedAva]:
                          orderItem.attributes.status === "Received",
                        [classes.preparingAva]:
                          orderItem.attributes.status === "Preparing",
                        [classes.readyAva]:
                          orderItem.attributes.status === "Ready",
                        [classes.doneAva]:
                          orderItem.attributes.status === "Done",
                      }),
                    }}
                  >
                    {orderItem.attributes.status === "Failed" ? (
                      <ErrorOutlineOutlinedIcon />
                    ) : (
                      <></>
                    )}
                    {orderItem.attributes.status === "Received" ? (
                      <SettingsBackupRestoreOutlinedIcon />
                    ) : (
                      <></>
                    )}
                    {orderItem.attributes.status === "Preparing" ? (
                      <WhatshotOutlinedIcon />
                    ) : (
                      <></>
                    )}
                    {orderItem.attributes.status === "Ready" ? (
                      <DoneOutlinedIcon />
                    ) : (
                      <></>
                    )}
                    {orderItem.attributes.status === "Done" ? (
                      <DoneAllOutlinedIcon />
                    ) : (
                      <></>
                    )}
                  </Avatar>

                  {!matchesSM ? (
                    <Typography variant="body1">{`${orderItem.attributes.status}`}</Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Box classes={{ root: classes.typeBox }}>
                  <Avatar
                    classes={{
                      root: clsx(classes.ordTypeAva, {
                        [classes.dineInAva]:
                          orderItem.attributes.orderType === "dineIn",
                        [classes.takeAwayAva]:
                          orderItem.attributes.orderType === "takeAway",
                      }),
                    }}
                  >
                    {orderItem.attributes.orderType === "dineIn" ? (
                      <FlatwareOutlinedIcon />
                    ) : (
                      <></>
                    )}
                    {orderItem.attributes.orderType === "takeAway" ? (
                      <TakeoutDiningOutlinedIcon />
                    ) : (
                      <></>
                    )}
                  </Avatar>

                  {!matchesSM ? (
                    <Typography
                      variant="body1"
                      classes={{ root: classes.stateText }}
                    >{`${
                      orderItem.attributes.orderType === "dineIn"
                        ? `Table: ${orderItem.attributes.orderTableNumber}`
                        : ""
                    }${
                      orderItem.attributes.orderType === "takeAway"
                        ? `${getHrMinText(
                            orderItem.attributes.orderPickUpTime
                          )}, ${orderItem.attributes.orderPickUpDate.label}`
                        : ""
                    }`}</Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={7}>
                <Box classes={{ root: classes.timeBox }}>
                  <Chip
                    variant="outlined"
                    icon={
                      !matchesSM ? (
                        <AccessTimeOutlinedIcon
                          classes={{ root: classes.iconSize }}
                        />
                      ) : undefined
                    }
                    label={`${getTimeAgo(orderItem.attributes.orderTime)}`}
                    classes={{ root: classes.chipTime }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                <Box classes={{ root: classes.timeBox }}>
                  <Chip
                    icon={
                      orderItem.attributes.paymentStatus === "received" ? (
                        <CheckCircleOutlinedIcon
                          classes={{ root: classes.iconSizeSucess }}
                        />
                      ) : orderItem.attributes.paymentStatus === "failed" ? (
                        <ErrorOutlineOutlinedIcon
                          classes={{ root: classes.iconSizeFail }}
                        />
                      ) : orderItem.attributes.paymentStatus ===
                        "notReceived" ? (
                        <HighlightOffOutlinedIcon
                          classes={{ root: classes.iconSizeFail }}
                        />
                      ) : orderItem.attributes.paymentStatus ===
                        "partRefunded" ? (
                        <RotateLeftOutlinedIcon
                          classes={{ root: classes.iconSize }}
                        />
                      ) : orderItem.attributes.paymentStatus ===
                        "fullRefunded" ? (
                        <FlipCameraAndroidOutlinedIcon
                          classes={{ root: classes.iconSize }}
                        />
                      ) : undefined
                    }
                    variant="outlined"
                    label={`${priceFormat(orderItem.attributes.total)}`}
                    classes={{ root: classes.chipPrice }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        fullScreen={true}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="order-details-admin-title"
        aria-describedby="order-details-admin-description"
        classes={{
          paperFullWidth: classes.dialogPaperFullWidth,
          paper: classes.dialogPaper,
          paperScrollPaper: classes.dialogPaperScrollPaper,
        }}
      >
        <OrderDialogCont
          orderItem={orderItem}
          setOpen={setOpen}
          storeSettings={storeSettings}
          user={user}
          dispatchFeedback={dispatchFeedback}
          ordersList={ordersList}
          setOrdersList={setOrdersList}
          index={index}
        />
      </Dialog>
    </>
  )
}

export default OrdersMainInCont
