import * as React from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import InputBase from "@material-ui/core/InputBase"
import Avatar from "@material-ui/core/Avatar"
import InputAdornment from "@material-ui/core/InputAdornment"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

import priceFormat from "../../../helperFunc/priceFormat"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainBox: {
    backgroundColor: theme.palette.common.blackTwo,
    padding: "0.5rem",
    borderRadius: "1rem",
  },
  butSolidBlack: {
    backgroundColor: theme.palette.common.blackTweleve,
    color: theme.palette.common.black,
    cursor: "pointer",
    height: "1.75rem",
    width: "1.75rem",
  },

  butSolidDisabled: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    color: `${theme.palette.common.blackFive} !important`,
  },

  textFieldNumberContainer: {
    maxWidth: "2rem",
  },

  iconSize: {
    fontSize: "1.2rem !important",
  },

  textFieldNumber: {
    color: theme.palette.common.white,
    paddingTop: "0 !important",
    fontSize: "1rem",
    lineHeight: "1rem !important",
    verticalAlign: "middle !important",
    backgroundColor: "transparent",
    borderRadius: "3rem",
    textAlignLast: "center",
    width: "2rem",
    borderWidth: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    "&:focus-visible": {
      outline: 0,
    },
  },

  refundTextBox: {
    backgroundColor: theme.palette.common.blackThree,
    display: "flex",
    alignItems: "baseline",
    borderRadius: "1rem",
    marginBottom: "0.5rem",
    padding: "0.25rem",
  },

  refundText: {
    padding: 0,
    fontWeight: 600,
    textAlign: "left",

    fontSize: "1rem",
    lineHeight: "1rem",
  },

  refundDoller: {
    marginLeft: "0.25rem",
  },

  counterGrid: {
    marginBottom: "0.5rem",
  },

  remainingText: {
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    marginLeft: "0.5rem",
    textAlign: "left",
    color: theme.palette.common.blackTweleve,
  },

  "@global": {
    "input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
}))

function CartCounter({
  qty,
  item,
  setRefundItems,
  index,
  refundItems,
  isLoading,
}) {
  const handleIncrement = () => {
    if (qty < item.qty) {
      let refundItemsCopy = [...refundItems]
      refundItemsCopy[index].refundQty = qty + 1

      let newRefundAmm = parseFloat(
        ((qty + 1) * item.totalItemPrice).toFixed(2)
      )
      if (newRefundAmm > item.availableToRefund) {
        newRefundAmm = item.availableToRefund
      }
      refundItemsCopy[index].refundAmmount =
        newRefundAmm === 0 ? "" : newRefundAmm
      setRefundItems(refundItemsCopy)
    }
  }

  const handleDecrement = () => {
    if (qty > 0) {
      let refundItemsCopy = [...refundItems]
      refundItemsCopy[index].refundQty = qty - 1

      let newRefundAmm = parseFloat(
        ((qty - 1) * item.totalItemPrice).toFixed(2)
      )
      if (newRefundAmm > item.availableToRefund) {
        newRefundAmm = item.availableToRefund
      }

      refundItemsCopy[index].refundAmmount =
        newRefundAmm === 0 ? "" : newRefundAmm

      setRefundItems(refundItemsCopy)
    }
  }

  const handleChange = e => {
    let refundItemsCopy = [...refundItems]
    if (
      e.nativeEvent.data !== "-" &&
      e.nativeEvent.data !== "+" &&
      e.nativeEvent.data !== "."
    ) {
      let valueIn = e.target.value
      if (isNaN(valueIn) || isNaN(parseFloat(valueIn))) {
        if (valueIn === "") {
          refundItemsCopy[index].refundAmmount = ""
          refundItemsCopy[index].refundQty = 0
          setRefundItems(refundItemsCopy)
        }
      } else {
        let value = parseFloat(valueIn)
        let newRefundAmm = parseFloat(value.toFixed(2))
        if (newRefundAmm > item.availableToRefund) {
          newRefundAmm = item.availableToRefund
        }
        if (newRefundAmm < 0) {
          refundItemsCopy[index].refundAmmount = ""

          refundItemsCopy[index].refundQty = 0
        } else {
          if (newRefundAmm === 0) {
            refundItemsCopy[index].refundQty = 0
          } else if (refundItemsCopy[index].refundQty === 0) {
            refundItemsCopy[index].refundQty = 1
          }
          let lastTwo = valueIn.slice(-2)
          if (valueIn === "0.0" || valueIn === ".0" || lastTwo === ".0") {
            refundItemsCopy[index].refundAmmount = valueIn
          } else {
            refundItemsCopy[index].refundAmmount = newRefundAmm
          }
        }
        //alert(newRefundAmm)
        setRefundItems(refundItemsCopy)
      }
    } else if (e.nativeEvent.data === ".") {
      let ammountStr = `${item.refundAmmount}`
      if (ammountStr.slice(ammountStr.length - 1) !== ".") {
        refundItemsCopy[index].refundAmmount = `${item.refundAmmount}.`
        setRefundItems(refundItemsCopy)
      }
    }
  }

  const handleKeyDown = e => {
    if (
      ((e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8 ||
        e.keyCode === 110 ||
        e.keyCode === 46 ||
        e.keyCode === 190) &&
      e.key !== "-"
    ) {
    } else {
      e.preventDefault()
    }
  }

  const classes = useStyles()

  return (
    <Box classes={{ root: classes.mainBox }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        classes={{ root: classes.counterGrid }}
      >
        <Grid item>
          <Avatar
            color="primary"
            onClick={handleDecrement}
            classes={{
              root: clsx(classes.butSolidBlack, {
                [classes.butSolidDisabled]: qty < 1 || isLoading,
              }),
            }}
          >
            <RemoveIcon classes={{ root: classes.iconSize }} />
          </Avatar>
        </Grid>
        <Grid xs item classes={{ root: classes.textFieldNumberContainer }}>
          <input className={classes.textFieldNumber} value={qty} readOnly />
        </Grid>

        <Grid item>
          <Avatar
            onClick={handleIncrement}
            classes={{
              root: clsx(classes.butSolidBlack, {
                [classes.butSolidDisabled]: qty >= item.qty || isLoading,
              }),
            }}
          >
            <AddIcon classes={{ root: classes.iconSize }} />
          </Avatar>
        </Grid>
      </Grid>
      <Box classes={{ root: classes.refundTextBox }}>
        <InputBase
          classes={{ input: classes.refundText }}
          value={item.refundAmmount}
          inputProps={{
            min: 0,
            inputMode: "decimal",
            pattern: "^d*(.d{0,2})?$",
          }}
          placeholder={"0.00"}
          readOnly={isLoading}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          startAdornment={
            <InputAdornment
              position="start"
              classes={{ root: classes.refundDoller }}
            >
              $
            </InputAdornment>
          }
          fullWidth
        />
      </Box>
      <Typography variant="body1" classes={{ root: classes.remainingText }}>
        {`${priceFormat(item.availableToRefund, null, true)} Avl.`}
      </Typography>
    </Box>
  )
}

export default CartCounter
