const getTimeAgo = (timeIn, getDate = false) => {
  let timeAgoInMin = Math.round((Date.now() - parseInt(timeIn)) / (60 * 1000))
  if (timeAgoInMin < 60 && !getDate) {
    return `${timeAgoInMin} minute${timeAgoInMin > 1 ? "s" : ""} ago`
  } else if (timeAgoInMin < 1440 && !getDate) {
    let timeAgoInHrs = Math.floor(timeAgoInMin / 60)
    return `${timeAgoInHrs} hour${timeAgoInHrs > 1 ? "s" : ""} ago`
  } else {
    let date = new Date(parseInt(timeIn))
    let options = getDate
      ? {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
      : { year: "numeric", month: "numeric", day: "numeric" }
    return date.toLocaleDateString("en-AU", options)
  }
}

export default getTimeAgo
