import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import InputBase from "@material-ui/core/InputBase"
import Button from "@material-ui/core/Button"
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainBox: { paddingBottom: "0.75rem" },
  textField: {
    backgroundColor: `${theme.palette.common.black} !important`,
    borderRadius: "1rem",
    padding: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    width: "100%",
  },
  butSearchFieldGrid: {
    maxWidth: "30rem",
    width: "calc(100% - 7.5rem)",
    paddingRight: "0.5rem",
  },
  mainGrid: {
    width: "100%",
  },
  butEndIcon: {
    margin: 0,
    padding: 0,
  },
  but: {
    padding: "0.5rem",
    borderRadius: "6rem",
    height: "3rem !important",
    width: "3rem !important",
    minWidth: "3rem !important",
  },
  butSearchGrid: {
    paddingRight: "0.5rem",
  },
}))

const OrderSearch = ({
  setQString,
  defaultQString,
  defPageSize,
  setPage,
  setSearchString,
  loading,
  searchString,
}) => {
  const [value, setValue] = React.useState("")
  const classes = useStyles()

  const emailRegExp = new RegExp(/^\S+@\S+\.\S+$/)
  const phoneStartWithZRegExp = new RegExp(/^(?=0)(\d{10})$/)
  const phoneStartWithPlusRegExp = new RegExp(/^\+61+(\d{9,10})\b/)
  const orderIdRegExp = new RegExp(/^\d{1,}$/)
  const moneyRegExp = new RegExp(
    /^\$?-?(((\d{1,3},?)(\d{3},?)+|\d{1,3})|\d+)(\.\d{1,2})?$/
  )

  const handleChange = event => {
    setValue(event.target.value)
  }

  const clearSearch = () => {
    setValue("")
    setSearchString("")
    setPage(1)
    setQString(
      `${defaultQString}&pagination[page]=1&pagination[pageSize]=${defPageSize}`
    )
  }

  const setSearch = searchString => {
    setSearchString(searchString)
    setPage(1)
    setQString(
      `${defaultQString}${searchString}&pagination[page]=1&pagination[pageSize]=${defPageSize}`
    )
  }

  const handleSearch = () => {
    let valueToTest = value.trim()
    let noSpaceValue = valueToTest.replace(/ /g, "")
    let email = ""
    let phoneNumber = ""
    let total = 0
    let orderId = 0
    if (emailRegExp.test(noSpaceValue)) {
      email = noSpaceValue.toLowerCase()

      setSearch(
        `&filters[users_permissions_user][receiptEmail][$eq]=${encodeURIComponent(
          email
        )}`
      )
    } else if (phoneStartWithZRegExp.test(noSpaceValue)) {
      phoneNumber = `+61${noSpaceValue.substring(1, 10)}`
      setSearch(
        `&filters[users_permissions_user][phone][$eq]=${encodeURIComponent(
          phoneNumber
        )}`
      )
    } else if (phoneStartWithPlusRegExp.test(noSpaceValue)) {
      if (noSpaceValue.length > 12) {
        phoneNumber = `${noSpaceValue.substring(0, 3)}${noSpaceValue.substring(
          4,
          13
        )}`
      } else {
        phoneNumber = noSpaceValue
      }
      setSearch(
        `&filters[users_permissions_user][phone][$eq]=${encodeURIComponent(
          phoneNumber
        )}`
      )
    } else if (orderIdRegExp.test(noSpaceValue)) {
      orderId = parseInt(noSpaceValue)

      setSearch(`&filters[id][$eq]=${orderId}`)
    } else if (moneyRegExp.test(noSpaceValue)) {
      if (noSpaceValue.substring(0, 1) === "$") {
        total = parseFloat(
          parseFloat(noSpaceValue.substring(1, noSpaceValue.length)).toFixed(2)
        )
      } else {
        total = parseFloat(
          parseFloat(noSpaceValue.substring(0, noSpaceValue.length)).toFixed(2)
        )
      }
      setSearch(`&filters[total][$eq]=${total}`)
    } else if (noSpaceValue.length > 0) {
      setSearch(
        `&filters[users_permissions_user][userDisplayName][$containsi]=${encodeURIComponent(
          valueToTest
        )}`
      )
    } else {
      clearSearch()
    }
  }

  const handleEnter = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSearch()
    }
  }

  return (
    <Box classes={{ root: classes.mainBox }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.mainGrid }}
      >
        <Grid item classes={{ root: classes.butSearchFieldGrid }}>
          <InputBase
            disabled={loading}
            className={classes.textField}
            id="standard-multiline-search"
            placeholder="Search by order #, phone #, email, name or order $"
            spellCheck="false"
            value={value}
            onChange={handleChange}
            onKeyPress={handleEnter}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item classes={{ root: classes.butSearchGrid }}>
              <Button
                disabled={value === "" || loading}
                aria-label="done"
                variant="contained"
                color="secondary"
                onClick={handleSearch}
                classes={{ endIcon: classes.butEndIcon, root: classes.but }}
                endIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={"1.75rem"} />
                  ) : (
                    <SearchOutlinedIcon />
                  )
                }
              ></Button>
            </Grid>
            <Grid item>
              <Button
                disabled={(value === "" && searchString === "") || loading}
                aria-label="cancel"
                variant="contained"
                onClick={clearSearch}
                classes={{ endIcon: classes.butEndIcon, root: classes.but }}
                endIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={"1.75rem"} />
                  ) : (
                    <ClearOutlinedIcon />
                  )
                }
              ></Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default OrderSearch
