let formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
})

const priceFormat = (price, PriceInfo = null, showZero = false) => {
  return price !== 0
    ? `${formatter.format(price)} ${PriceInfo ? PriceInfo : ""}`
    : showZero
    ? "$0.00"
    : ""
}

export default priceFormat
