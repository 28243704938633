import * as React from "react"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined"
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"

import priceFormat from "../../../helperFunc/priceFormat"
import CartItem from "./cartItem"
import CartServiceItem from "./cartServiceItem"
import handleError from "../../../helperFunc/handleError"
import handleSuccess from "../../../helperFunc/handleSuccess"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderNotesHead: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  orderNotesText: {
    lineHeight: "1rem",
  },
  refundBox: {
    position: "sticky",
    top: 0,
    zIndex: 100,
    marginBottom: "1rem",
    borderRadius: "0.75rem",
    padding: "1rem",
    textAlign: "left",
    borderStyle: "solid",
    borderColor: theme.palette.common.blackNine,
    borderWidth: "1px",
    backgroundColor: `${theme.palette.common.black}a0`,
    backdropFilter: "blur(3px)",
    "-webkit-backdrop-filter": "blur(3px)",
  },
  orderItemBox: {
    marginBottom: "0.75rem",

    paddingBottom: "0.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",

    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2px",
  },

  butText: {
    overflowWrap: "break-word",
  },

  refundBut: {
    lineHeight: "1rem !important",
    borderRadius: "6rem",
    textAlign: "left !important",
    minHeight: "3rem !important",
  },

  fullRefundBut: {
    lineHeight: "1rem !important",
    borderRadius: "6rem",
    textAlign: "left !important",
    minHeight: "3rem !important",
    borderWidth: "1px",
    borderColor: theme.palette.common.blackNine,
  },

  butEndIcon: {
    margin: 0,
    padding: 0,
  },
  but: {
    padding: "0.5rem",
    borderRadius: "6rem",
    height: "3rem !important",
    width: "3rem !important",
    minWidth: "3rem !important",
  },

  refundTextBox: {
    display: "flex",
  },
  refundTextBold: {
    marginLeft: "0.5rem",
    fontWeight: 900,
  },

  alertTitle: {
    fontSize: "1.75rem !important",
    marginTop: "0.5rem",
    lineHeight: "2rem !important",
  },

  dialogText: {
    fontSize: "1.25rem !important",
    lineHeight: "1.5rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  butDilog: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  boxRightBut: {
    paddingLeft: "0.75rem",
  },
}))

const OrderCart = ({
  orderItem,
  user,
  dispatchFeedback,
  ordersList,
  setOrdersList,
  index,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [isFullRefund, setIsFullRefund] = React.useState(false)
  const [isPartRefund, setIsPartRefund] = React.useState(false)
  const [refundAmmount, setRefundAmmount] = React.useState(0)
  const [refundSubTotal, setRefundSubTotal] = React.useState(0)
  const [refundServiceFee, setRefundServiceFee] = React.useState("")
  const [refundPhSurchargeFee, setRefundPhSurchargeFee] = React.useState("")
  const [refundSatSurchargeFee, setRefundSatSurchargeFee] = React.useState("")
  const [refundSunSurchargeFee, setRefundSunSurchargeFee] = React.useState("")
  const [refundItems, setRefundItems] = React.useState([])

  const classes = useStyles()
  const matchesSM = useMediaQuery(theme => theme.breakpoints.down("sm"))

  const availableToRefund = parseFloat(
    (
      orderItem.attributes.total -
      (orderItem.attributes.refundAmount
        ? orderItem.attributes.refundAmount
        : 0)
    ).toFixed(2)
  )

  const availableSubTotalToRefund = parseFloat(
    (
      orderItem.attributes.subtotal -
      (orderItem.attributes.refundSubtotal
        ? orderItem.attributes.refundSubtotal
        : 0)
    ).toFixed(2)
  )

  const availableRefundServiceFee = parseFloat(
    (
      orderItem.attributes.serviceFee -
      (orderItem.attributes.refundServiceFee
        ? orderItem.attributes.refundServiceFee
        : 0)
    ).toFixed(2)
  )

  const availableRefundPhSurchargeFee = parseFloat(
    (
      orderItem.attributes.phSurchargeFee -
      (orderItem.attributes.refundPhSurchargeFee
        ? orderItem.attributes.refundPhSurchargeFee
        : 0)
    ).toFixed(2)
  )

  const availableRefundSatSurchargeFee = parseFloat(
    (
      orderItem.attributes.satSurchargeFee -
      (orderItem.attributes.refundSatSurchargeFee
        ? orderItem.attributes.refundSatSurchargeFee
        : 0)
    ).toFixed(2)
  )

  const availableRefundSunSurchargeFee = parseFloat(
    (
      orderItem.attributes.sunSurchargeFee -
      (orderItem.attributes.refundSunSurchargeFee
        ? orderItem.attributes.refundSunSurchargeFee
        : 0)
    ).toFixed(2)
  )

  const handleClose = () => {
    setOpen(false)
  }

  const handleRefundCancel = () => {
    setRefundAmmount(0)
    setRefundSubTotal(0)
    setRefundServiceFee("")
    setRefundPhSurchargeFee("")
    setRefundSatSurchargeFee("")
    setRefundSunSurchargeFee("")
    setIsFullRefund(false)
    setIsPartRefund(false)
    setRefundItems(resetItems([...orderItem.attributes.items]))
  }

  const handleRefundSend = () => {
    setIsLoading(true)
    handleClose()

    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/kitchen-setting/ord-ref",
        {
          id: orderItem.id,
          isFullRefund: isFullRefund,
          refundItems: refundItems,
          refundAmmount: refundAmmount,
          refundSubTotal: refundSubTotal,
          refundServiceFee: refundServiceFee,
          refundPhSurchargeFee: refundPhSurchargeFee,
          refundSatSurchargeFee: refundSatSurchargeFee,
          refundSunSurchargeFee: refundSunSurchargeFee,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        axios
          .get(
            process.env.GATSBY_STRAPI_URL +
              `/api/orders/${orderItem.id}?populate[0]=users_permissions_user`,
            {
              headers: {
                Authorization: `Bearer ${user.jwt}`,
              },
            }
          )
          .then(res => {
            setIsLoading(false)
            setIsFullRefund(false)
            setIsPartRefund(false)
            handleSuccess("Refunded Successfully", dispatchFeedback)
            let ordersListCopy = [...ordersList]
            ordersListCopy[index] = res.data.data
            setOrdersList(ordersListCopy)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  const resetItems = refundItemsCopy => {
    for (let i = 0; i < refundItemsCopy.length; i++) {
      //refundItemsCopy[i].itemRefundAmount = 2
      refundItemsCopy[i].refundQty = 0
      refundItemsCopy[i].refundAmmount = ""
      refundItemsCopy[i].availableToRefund = parseFloat(
        (
          refundItemsCopy[i].qty * refundItemsCopy[i].totalItemPrice -
          (refundItemsCopy[i].itemRefundAmount
            ? refundItemsCopy[i].itemRefundAmount
            : 0)
        ).toFixed(2)
      )
    }
    return refundItemsCopy
  }

  React.useEffect(() => {
    setRefundItems(resetItems([...orderItem.attributes.items]))
  }, [orderItem])

  React.useEffect(() => {
    let refundItemsCopy = [...refundItems]
    let newRefundAmmount = 0
    for (let i = 0; i < refundItemsCopy.length; i++) {
      let newItemRefundAmm = isNaN(parseFloat(refundItemsCopy[i].refundAmmount))
        ? 0
        : parseFloat(parseFloat(refundItemsCopy[i].refundAmmount).toFixed(2))
      newRefundAmmount = newRefundAmmount + newItemRefundAmm
    }

    if (newRefundAmmount > availableSubTotalToRefund) {
      newRefundAmmount = availableSubTotalToRefund
    }
    setRefundSubTotal(newRefundAmmount)

    let newServiceFeeRefund = 0
    let newPhSurchargeFeeRefund = 0
    let newSatSurchargeFeeRefund = 0
    let newSunSurchargeFeeRefund = 0

    if (orderItem.attributes.serviceFee > 0) {
      let serviceFeeRate = parseFloat(
        orderItem.attributes.serviceFeeRate.toFixed(2)
      )
      newServiceFeeRefund = parseFloat(
        (
          Math.round(newRefundAmmount * (serviceFeeRate / 100) * 100) / 100
        ).toFixed(2)
      )

      if (
        newServiceFeeRefund > availableRefundServiceFee ||
        availableRefundServiceFee - newServiceFeeRefund < 0.05
      ) {
        newServiceFeeRefund = availableRefundServiceFee
      }
    }

    if (orderItem.attributes.phSurchargeFee > 0) {
      let phSurchargeRate = parseFloat(
        orderItem.attributes.phSurchargeRate.toFixed(2)
      )
      newPhSurchargeFeeRefund = parseFloat(
        (
          Math.round(newRefundAmmount * (phSurchargeRate / 100) * 100) / 100
        ).toFixed(2)
      )

      if (
        newPhSurchargeFeeRefund > availableRefundPhSurchargeFee ||
        availableRefundPhSurchargeFee - newPhSurchargeFeeRefund < 0.05
      ) {
        newPhSurchargeFeeRefund = availableRefundPhSurchargeFee
      }
    }

    if (orderItem.attributes.satSurchargeFee > 0) {
      let satSurchargeRate = parseFloat(
        orderItem.attributes.satSurchargeRate.toFixed(2)
      )
      newSatSurchargeFeeRefund = parseFloat(
        (
          Math.round(newRefundAmmount * (satSurchargeRate / 100) * 100) / 100
        ).toFixed(2)
      )

      if (
        newSatSurchargeFeeRefund > availableRefundSatSurchargeFee ||
        availableRefundSatSurchargeFee - newSatSurchargeFeeRefund < 0.05
      ) {
        newSatSurchargeFeeRefund = availableRefundSatSurchargeFee
      }
    }

    if (orderItem.attributes.sunSurchargeFee > 0) {
      let sunSurchargeRate = parseFloat(
        orderItem.attributes.sunSurchargeRate.toFixed(2)
      )
      newSunSurchargeFeeRefund = parseFloat(
        (
          Math.round(newRefundAmmount * (sunSurchargeRate / 100) * 100) / 100
        ).toFixed(2)
      )

      if (
        newSunSurchargeFeeRefund > availableRefundSunSurchargeFee ||
        availableRefundSunSurchargeFee - newSunSurchargeFeeRefund < 0.05
      ) {
        newSunSurchargeFeeRefund = availableRefundSunSurchargeFee
      }
    }

    setRefundServiceFee(newServiceFeeRefund === 0 ? "" : newServiceFeeRefund)

    setRefundPhSurchargeFee(
      newPhSurchargeFeeRefund === 0 ? "" : newPhSurchargeFeeRefund
    )
    setRefundSatSurchargeFee(
      newSatSurchargeFeeRefund === 0 ? "" : newSatSurchargeFeeRefund
    )
    setRefundSunSurchargeFee(
      newSunSurchargeFeeRefund === 0 ? "" : newSunSurchargeFeeRefund
    )
  }, [
    refundItems,
    availableRefundServiceFee,
    availableSubTotalToRefund,
    availableRefundPhSurchargeFee,
    availableRefundSatSurchargeFee,
    availableRefundSunSurchargeFee,
    orderItem,
  ])

  React.useEffect(() => {
    let newRefundServiceFee = isNaN(parseFloat(refundServiceFee))
      ? 0
      : parseFloat(parseFloat(refundServiceFee).toFixed(2))

    let newRefundPhSurchargeFee = isNaN(parseFloat(refundPhSurchargeFee))
      ? 0
      : parseFloat(parseFloat(refundPhSurchargeFee).toFixed(2))

    let newRefundSatSurchargeFee = isNaN(parseFloat(refundSatSurchargeFee))
      ? 0
      : parseFloat(parseFloat(refundSatSurchargeFee).toFixed(2))

    let newRefundSunSurchargeFee = isNaN(parseFloat(refundSunSurchargeFee))
      ? 0
      : parseFloat(parseFloat(refundSunSurchargeFee).toFixed(2))

    let newRefundAmmount =
      refundSubTotal +
      newRefundServiceFee +
      newRefundPhSurchargeFee +
      newRefundSatSurchargeFee +
      newRefundSunSurchargeFee

    if (newRefundAmmount > availableToRefund) {
      newRefundAmmount = availableToRefund
    }
    setRefundAmmount(newRefundAmmount)
  }, [
    refundServiceFee,
    refundPhSurchargeFee,
    refundSatSurchargeFee,
    refundSunSurchargeFee,
    refundSubTotal,
    availableToRefund,
  ])

  return (
    <>
      <Box classes={{ root: classes.orderItemBox }}>
        {availableToRefund > 0 && orderItem.attributes.status !== "Failed" ? (
          <Box classes={{ root: classes.refundBox }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              {!isFullRefund && !isPartRefund ? (
                <>
                  <Grid item xs={matchesSM ? 5 : undefined}>
                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={e => {
                        setRefundAmmount(availableToRefund)
                        setIsFullRefund(true)
                      }}
                      size={"large"}
                      startIcon={<FlipCameraAndroidOutlinedIcon />}
                      classes={{
                        root: classes.fullRefundBut,
                        text: classes.butText,
                      }}
                    >
                      {"Full Refund"}
                    </Button>
                  </Grid>

                  <Grid item xs={matchesSM ? 5 : undefined}>
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={e => {
                        setIsPartRefund(true)
                      }}
                      size={"large"}
                      startIcon={<RotateLeftOutlinedIcon />}
                      classes={{
                        root: classes.refundBut,
                        text: classes.butText,
                      }}
                    >
                      {"Partial Refund"}
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs>
                    <Box classes={{ root: classes.refundTextBox }}>
                      <Typography variant="h6">{`Refund`}</Typography>
                      <Typography
                        variant="h6"
                        classes={{ root: classes.refundTextBold }}
                      >{`${priceFormat(
                        refundAmmount,
                        null,
                        true
                      )}`}</Typography>
                    </Box>

                    <Typography variant="body1">{`${priceFormat(
                      availableToRefund,
                      null,
                      true
                    )} Avl. to refund`}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        classes={{
                          root: classes.boxRightBut,
                        }}
                      >
                        <Button
                          disabled={
                            refundAmmount <= 0 ||
                            refundAmmount > availableToRefund ||
                            isLoading
                          }
                          aria-label="refundYes"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setOpen(true)
                          }}
                          classes={{
                            endIcon: classes.butEndIcon,
                            root: classes.but,
                          }}
                          endIcon={
                            isLoading ? (
                              <CircularProgress
                                color="inherit"
                                size={"1.75rem"}
                              />
                            ) : (
                              <DoneOutlinedIcon />
                            )
                          }
                        ></Button>
                      </Grid>

                      <Grid
                        item
                        classes={{
                          root: classes.boxRightBut,
                        }}
                      >
                        <Button
                          disabled={isLoading}
                          aria-label="refundNo"
                          variant="contained"
                          onClick={handleRefundCancel}
                          classes={{
                            endIcon: classes.butEndIcon,
                            root: classes.but,
                          }}
                          endIcon={
                            isLoading ? (
                              <CircularProgress
                                color="inherit"
                                size={"1.75rem"}
                              />
                            ) : (
                              <CloseOutlinedIcon />
                            )
                          }
                        ></Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        ) : (
          <></>
        )}
        {orderItem.attributes.orderNotes &&
        orderItem.attributes.orderNotes.length > 0 ? (
          <>
            <Typography
              variant="body2"
              classes={{ root: classes.orderNotesHead }}
            >
              {"ORDER NOTES"}
            </Typography>
            <Typography
              variant="body1"
              classes={{ root: classes.orderNotesText }}
            >
              {orderItem.attributes.orderNotes}
            </Typography>
            <Divider classes={{ root: classes.divider }} />
          </>
        ) : (
          <></>
        )}

        {refundItems.map((item, ind) => (
          <CartItem
            item={item}
            key={ind}
            last={ind + 1 === refundItems.length}
            orderItem={orderItem}
            user={user}
            dispatchFeedback={dispatchFeedback}
            ordersList={ordersList}
            setOrdersList={setOrdersList}
            index={ind}
            refundItems={refundItems}
            setRefundItems={setRefundItems}
            isPartRefund={isPartRefund}
            isLoading={isLoading}
          ></CartItem>
        ))}
        <CartServiceItem
          orderItem={orderItem}
          refundServiceFee={refundServiceFee}
          refundPhSurchargeFee={refundPhSurchargeFee}
          refundSatSurchargeFee={refundSatSurchargeFee}
          refundSunSurchargeFee={refundSunSurchargeFee}
          setRefundServiceFee={setRefundServiceFee}
          setRefundPhSurchargeFee={setRefundPhSurchargeFee}
          setRefundSatSurchargeFee={setRefundSatSurchargeFee}
          setRefundSunSurchargeFee={setRefundSunSurchargeFee}
          isLoading={isLoading}
          isPartRefund={isPartRefund}
          availableRefundServiceFee={availableRefundServiceFee}
          availableRefundPhSurchargeFee={availableRefundPhSurchargeFee}
          availableRefundSatSurchargeFee={availableRefundSatSurchargeFee}
          availableRefundSunSurchargeFee={availableRefundSunSurchargeFee}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby={`refund-title`}
        aria-describedby={`refund-description`}
      >
        <DialogTitle
          id={`refund-title`}
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>{"Confirm Refund"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{ root: classes.dialogText }}
            id={`refund-description`}
          >
            {`Are you sure you wish to proceed with the refund of ${priceFormat(
              refundAmmount,
              null,
              true
            )} for order number #${orderItem.id}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.butDilog }}
              >
                <Typography variant="body1">{"No"}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleRefundSend}
                variant="contained"
                size="large"
                disableElevation
                fullWidth
                classes={{ root: classes.butDilog }}
              >
                <Typography variant="body1">{"Yes"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OrderCart
