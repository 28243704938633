import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"

import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined"
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined"
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined"
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import TransitEnterexitOutlinedIcon from "@mui/icons-material/TransitEnterexitOutlined"

import getTimeAgo from "../helperFunc/getTimeAgo"
import priceFormat from "../../../helperFunc/priceFormat"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderItemBox: {
    marginBottom: "0.5rem",
    borderRadius: "0.75rem",
    paddingTop: "0.25rem",

    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    textAlign: "left",
    maxHeight: "calc(100vh  - 290px)",
    overflowY: "scroll",
    backgroundColor: theme.palette.common.blackTwo,
  },

  logBox: {
    marginBottom: "0.5rem",
    borderRadius: "0.75rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    textAlign: "left",
  },
  logBoxStatus: {
    backgroundColor: theme.palette.common.black,
  },
  logBoxRefund: {
    backgroundColor: theme.palette.common.blackFour,
  },

  greyText: {
    color: theme.palette.common.blackNine,
    marginRight: "0.25rem",
  },

  boldText: {
    lineHeight: "1rem",
    fontWeight: 500,
    overflowWrap: "anywhere",
  },

  boldTextTitle: {
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
    overflowWrap: "break-word",
    marginBottom: "0.25rem",
  },

  ordStatus: {
    width: "2rem !important",
    height: "2rem !important",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
  },

  failedAva: {
    backgroundColor: theme.palette.common.red,
  },
  placedAva: {
    backgroundColor: theme.palette.common.blackThree,
  },
  receivedAva: {
    backgroundColor: theme.palette.common.received,
  },
  preparingAva: {
    backgroundColor: theme.palette.common.preparing,
  },
  readyAva: {
    backgroundColor: theme.palette.common.ready,
  },
  doneAva: {
    backgroundColor: theme.palette.common.done,
  },

  textBox: {
    display: "flex",
    alignItems: "center",
  },

  statusBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },

  typeBox: {
    paddingTop: "0.15rem",
    paddingBottom: "0.15rem",
    display: "flex",
    alignItems: "center",
  },
  totalBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },
  refundBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    [theme.breakpoints.up("sm")]: {
      marginBottom: ({ state }) => (state !== "Failed" ? "1.5rem" : 0),
    },
  },
  chipTime: {
    height: "1.75rem",
    backgroundColor: theme.palette.common.black,
  },
  chipPrice: {
    height: "1.75rem",
    backgroundColor: theme.palette.common.blackThree,
  },

  iconSize: {
    fontSize: "1.25rem !important",
  },

  iconSizeText: {
    fontSize: "1rem !important",
    marginRight: "0.25rem",
  },

  divider: {
    height: "2px",
    marginBottom: "0.25rem",
    marginTop: "0.5rem",
  },

  logTitle: {
    fontSize: "1rem",
    fontWeight: 600,
  },

  stateTit: {
    lineHeight: "1rem",
    fontWeight: 400,
  },
  refundTit: {
    lineHeight: "1rem",
    fontWeight: 400,
    marginBottom: "0.25rem",
  },
  stateText: {
    lineHeight: "1rem",
    fontWeight: 600,
  },
  footerText: {
    fontSize: "0.75rem",
  },
}))

const OrderLog = ({ orderItem }) => {
  const classes = useStyles({ state: orderItem.attributes.status })

  return (
    <>
      <Typography variant="body1" classes={{ root: classes.boldTextTitle }}>
        {"ORDER HISTORY LOG"}
      </Typography>
      <Box classes={{ root: classes.orderItemBox }}>
        <Box classes={{ root: classes.statusBox }}>
          {orderItem.attributes.orderLog
            .slice(0)
            .reverse()
            .map((log, index) => {
              return (
                <Box
                  key={index}
                  classes={{
                    root: clsx(classes.logBox, {
                      [classes.logBoxStatus]: log.logType === "statusChange",
                      [classes.logBoxRefund]: log.logType === "refund",
                    }),
                  }}
                >
                  <Typography
                    variant="body1"
                    classes={{ root: classes.logTitle }}
                  >
                    {`${
                      log.logType === "statusChange"
                        ? log.statusFrom === ""
                          ? "Order Placed"
                          : "Order Status Change"
                        : ""
                    }`}
                    {`${
                      log.logType === "refund"
                        ? log.refundType === "fullRefunded"
                          ? "Order Fully Refunded"
                          : log.refundType === "partRefunded"
                          ? "Order Partially Refunded"
                          : ""
                        : ""
                    }`}
                  </Typography>

                  {log.logType === "refund" ? (
                    <Box classes={{ root: classes.totalBox }}>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.refundTit }}
                      >{`Refund Amount`}</Typography>
                      <Chip
                        icon={
                          log.refundType === "partRefunded" ? (
                            <RotateLeftOutlinedIcon
                              classes={{ root: classes.iconSize }}
                            />
                          ) : log.refundType === "fullRefunded" ? (
                            <FlipCameraAndroidOutlinedIcon
                              classes={{ root: classes.iconSize }}
                            />
                          ) : undefined
                        }
                        variant="outlined"
                        label={`${priceFormat(log.refundAmount)}`}
                        classes={{ root: classes.chipPrice }}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                  {log.logType === "statusChange" && log.statusFrom !== "" ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Box classes={{ root: classes.typeBox }}>
                          <Avatar
                            classes={{
                              root: clsx(classes.ordStatus, {
                                [classes.failedAva]:
                                  log.statusFrom === "Failed",
                                [classes.placedAva]:
                                  log.statusFrom === "Placed",
                                [classes.receivedAva]:
                                  log.statusFrom === "Received",
                                [classes.preparingAva]:
                                  log.statusFrom === "Preparing",
                                [classes.readyAva]: log.statusFrom === "Ready",
                                [classes.doneAva]: log.statusFrom === "Done",
                              }),
                            }}
                          >
                            {log.statusFrom === "Failed" ? (
                              <ErrorOutlineOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusFrom === "Placed" ? (
                              <TransitEnterexitOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusFrom === "Received" ? (
                              <SettingsBackupRestoreOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusFrom === "Preparing" ? (
                              <WhatshotOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusFrom === "Ready" ? (
                              <DoneOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusFrom === "Done" ? (
                              <DoneAllOutlinedIcon />
                            ) : (
                              <></>
                            )}
                          </Avatar>
                          <Box>
                            <Typography
                              variant="body1"
                              classes={{ root: classes.stateTit }}
                            >{`From`}</Typography>
                            <Typography
                              variant="body1"
                              classes={{ root: classes.stateText }}
                            >{`${log.statusFrom}`}</Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box classes={{ root: classes.typeBox }}>
                          <Avatar
                            classes={{
                              root: clsx(classes.ordStatus, {
                                [classes.failedAva]: log.statusTo === "Failed",
                                [classes.placedAva]: log.statusTo === "Placed",
                                [classes.receivedAva]:
                                  log.statusTo === "Received",
                                [classes.preparingAva]:
                                  log.statusTo === "Preparing",
                                [classes.readyAva]: log.statusTo === "Ready",
                                [classes.doneAva]: log.statusTo === "Done",
                              }),
                            }}
                          >
                            {log.statusTo === "Failed" ? (
                              <ErrorOutlineOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusTo === "Placed" ? (
                              <TransitEnterexitOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusTo === "Received" ? (
                              <SettingsBackupRestoreOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusTo === "Preparing" ? (
                              <WhatshotOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusTo === "Ready" ? (
                              <DoneOutlinedIcon />
                            ) : (
                              <></>
                            )}
                            {log.statusTo === "Done" ? (
                              <DoneAllOutlinedIcon />
                            ) : (
                              <></>
                            )}
                          </Avatar>
                          <Box>
                            <Typography
                              variant="body1"
                              classes={{ root: classes.stateTit }}
                            >{`To`}</Typography>
                            <Typography
                              variant="body1"
                              classes={{ root: classes.stateText }}
                            >{`${log.statusTo}`}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Divider classes={{ root: classes.divider }} />
                  <Box classes={{ root: classes.textBox }}>
                    <AccessTimeOutlinedIcon
                      classes={{ root: classes.iconSizeText }}
                    />
                    <Typography
                      variant="body1"
                      classes={{ root: classes.footerText }}
                    >
                      {`${getTimeAgo(log.time, true)}`}
                    </Typography>
                  </Box>
                  <Box classes={{ root: classes.textBox }}>
                    <PersonOutlineOutlinedIcon
                      classes={{ root: classes.iconSizeText }}
                    />
                    {log.refundedBy ? (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.footerText }}
                      >
                        {`Refunded by: ${log.refundedBy}`}
                      </Typography>
                    ) : (
                      <></>
                    )}
                    {log.changedBy ? (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.footerText }}
                      >
                        {`Changed by: ${log.changedBy}`}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              )
            })}
        </Box>
      </Box>
      <Box classes={{ root: classes.detailsBox }}>
        <Typography
          variant="body1"
          classes={{ root: classes.greyText }}
        >{`Transaction ID`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.boldText }}
        >{`${orderItem.attributes.transaction}`}</Typography>
      </Box>
    </>
  )
}

export default OrderLog
