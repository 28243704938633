import * as React from "react"
import Grid from "@material-ui/core/Grid"

import OrderDetails from "./orderDetails"
import OrderCart from "./orderCart"
import OrderLog from "./orderLog"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  firstGrid: {
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: 0,
    },
  },
  secondGrid: {},
  thirdGrid: {
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: 0,
    },
  },
}))

const OrderDetailsCont = ({
  orderItem,
  user,
  dispatchFeedback,
  ordersList,
  setOrdersList,
  index,
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={4} md={3} classes={{ root: classes.firstGrid }}>
        <OrderDetails
          orderItem={orderItem}
          user={user}
          dispatchFeedback={dispatchFeedback}
          ordersList={ordersList}
          setOrdersList={setOrdersList}
          index={index}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={6} classes={{ root: classes.secondGrid }}>
        <OrderCart
          orderItem={orderItem}
          user={user}
          dispatchFeedback={dispatchFeedback}
          ordersList={ordersList}
          setOrdersList={setOrdersList}
          index={index}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} classes={{ root: classes.thirdGrid }}>
        <OrderLog orderItem={orderItem} />
      </Grid>
    </Grid>
  )
}

export default OrderDetailsCont
