import * as React from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"

import priceFormat from "../../../helperFunc/priceFormat"
import CartCounter from "./cartCounter"

import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  textContainer: {
    paddingRight: "1.5rem",
  },

  AvatarLabelBox: {
    display: "flex",
    alignItems: "flex-start",
  },

  unavailable: {
    backgroundColor: "rgb(253, 237, 237)",
    color: "#ef5350",
  },

  avatar: {
    width: "3rem",
    height: "3rem",
    marginRight: "0.5rem",
  },
  descriptionText: {
    color: theme.palette.common.blackNine,
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    display: "inline-block",
    marginRight: "0.2rem",
    overflowWrap: "anywhere",
  },

  descriptionTextBold: {
    lineHeight: "1.1rem",
    display: "inline-block",
    fontWeight: 600,
  },

  descriptionTextBox: {
    lineHeight: "0.5rem",
    marginTop: "0.1rem",
  },

  itemHeading: {
    fontSize: "1.1rem",
    lineHeight: "1.3rem",
  },

  qtyChip: {
    marginRight: "0.5rem",
    height: "1.25rem",
    backgroundColor: theme.palette.common.black,
  },
  qtyText: {
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 600,
  },
  priceChip: {
    width: "100%",
    marginTop: 0,
    marginBottom: "0.3rem",
    height: "1.75rem",
    backgroundColor: theme.palette.common.black,
  },

  price: { fontSize: "1rem", lineHeight: "1rem" },

  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2px",
  },

  iconSize: {
    fontSize: "1rem !important",
  },

  refundChip: {
    width: "100%",
    marginTop: 0,
    marginBottom: "0.4rem",
    height: "1.25rem",
    backgroundColor: theme.palette.common.blackFive,
  },

  chipGrid: {
    maxWidth: "6.5rem",
    textAlign: "right",
  },
}))

const CartItem = ({
  item,
  last,
  isPartRefund,
  setRefundItems,
  index,
  refundItems,
  isLoading,
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          classes={{ root: classes.mainContainer }}
        >
          <Grid item classes={{ root: classes.textContainer }}>
            <Box classes={{ root: classes.AvatarLabelBox }}>
              <Chip
                label={
                  <Typography
                    variant="body1"
                    classes={{ root: classes.qtyText }}
                  >
                    {`${item.qty}x`}
                  </Typography>
                }
                classes={{ root: classes.qtyChip }}
              ></Chip>
              <Typography variant="h6" classes={{ root: classes.itemHeading }}>
                {item.name}
              </Typography>
            </Box>

            {Object.keys(item.orderSelectedItems).map((objkey, index) =>
              item.orderSelectedItems[objkey].fieldPrintLabel ? (
                <Box key={index} classes={{ root: classes.descriptionTextBox }}>
                  <Typography
                    variant="body1"
                    classes={{
                      root: clsx(
                        classes.descriptionText,
                        classes.descriptionTextBold
                      ),
                    }}
                  >
                    {`${item.orderSelectedItems[objkey].fieldPrintLabel}`}
                  </Typography>

                  {item.orderSelectedItems[objkey].selectedItems[0]
                    .optionTextVal ? (
                    <Typography
                      variant="body1"
                      classes={{ root: classes.descriptionText }}
                    >
                      {`${item.orderSelectedItems[objkey].selectedItems[0].optionTextVal}`}
                    </Typography>
                  ) : (
                    item.orderSelectedItems[objkey].selectedItems.map(
                      (inSelectedItem, index) => {
                        return (
                          <Typography
                            variant="body1"
                            classes={{ root: classes.descriptionText }}
                            key={index}
                          >
                            {`${
                              item.orderSelectedItems[objkey].fieldType ===
                                "quantityBox" || inSelectedItem.quantity > 1
                                ? `${inSelectedItem.quantity}x `
                                : ""
                            }${inSelectedItem.optionPrintLabel}${
                              index + 1 <
                              item.orderSelectedItems[objkey].selectedItems
                                .length
                                ? `, `
                                : ""
                            }`}
                          </Typography>
                        )
                      }
                    )
                  )}
                </Box>
              ) : null
            )}
          </Grid>
          <Grid item classes={{ root: classes.chipGrid }}>
            <Chip
              label={
                <Typography variant="body1" classes={{ root: classes.price }}>
                  {`${priceFormat(
                    parseFloat((item.qty * item.totalItemPrice).toFixed(2)),
                    null,
                    true
                  )}`}
                </Typography>
              }
              classes={{ root: classes.priceChip }}
            ></Chip>
            {item.itemRefundAmount && item.itemRefundAmount > 0 ? (
              <Chip
                icon={
                  <CurrencyExchangeOutlinedIcon
                    classes={{ root: classes.iconSize }}
                  />
                }
                label={`${priceFormat(item.itemRefundAmount, null, true)}`}
                classes={{ root: classes.refundChip }}
              />
            ) : (
              <></>
            )}

            {isPartRefund && item.availableToRefund > 0 ? (
              <CartCounter
                qty={item.refundQty}
                item={item}
                refundItems={refundItems}
                setRefundItems={setRefundItems}
                index={index}
                isLoading={isLoading}
              ></CartCounter>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!last && <Divider classes={{ root: classes.divider }} />}
    </>
  )
}

export default CartItem
