import * as React from "react"
import axios from "axios"
import OrdersMainInCont from "./ordersMainInCont"

import handleError from "../../../helperFunc/handleError"
const defaultQString =
  "/api/orders?populate[0]=users_permissions_user&sort[0]=id%3Adesc&filters[status][$in][0]=Done&filters[status][$in][1]=Ready&filters[status][$in][2]=Received&filters[status][$in][3]=Preparing&filters[status][$in][4]=Failed"
const defPageSize = 20

const OrdersMain = ({
  user,
  kitchenSettings,
  dispatchFeedback,
  storeSettings,
  location,
}) => {
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [qString, setQString] = React.useState("")
  const [searchString, setSearchString] = React.useState("")
  const [pageCount, setPageCount] = React.useState(1)
  const [ordersList, setOrdersList] = React.useState([])

  const requestNewPage = value => {
    setQString(
      `${defaultQString}${searchString}&pagination[page]=${value}&pagination[pageSize]=${defPageSize}`
    )
    setPage(value)
  }

  React.useEffect(() => {
    if (qString !== "") {
      setLoading(true)
      axios
        .get(process.env.GATSBY_STRAPI_URL + `${qString}`, {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        })
        .then(response => {
          setLoading(false)
          setOrdersList([...response.data.data])
          setPageCount(response.data.meta.pagination.pageCount)
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          handleError(error, dispatchFeedback)
        })
    }
  }, [dispatchFeedback, qString, user.jwt])

  React.useEffect(() => {
    setLoading(true)
    axios
      .get(
        process.env.GATSBY_STRAPI_URL +
          `${defaultQString}&pagination[page]=1&pagination[pageSize]=${defPageSize}`,
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setLoading(false)
        setOrdersList([...response.data.data])
        setPageCount(response.data.meta.pagination.pageCount)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }, [dispatchFeedback, user.jwt])

  return (
    <>
      <OrdersMainInCont
        user={user}
        kitchenSettings={kitchenSettings}
        dispatchFeedback={dispatchFeedback}
        storeSettings={storeSettings}
        setOrdersList={setOrdersList}
        ordersList={ordersList}
        page={page}
        requestNewPage={requestNewPage}
        pageCount={pageCount}
        setPage={setPage}
        setQString={setQString}
        loading={loading}
        location={location}
        defaultQString={defaultQString}
        defPageSize={defPageSize}
        setSearchString={setSearchString}
        searchString={searchString}
      />
    </>
  )
}

export default OrdersMain
