import * as React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Pagination from "@material-ui/lab/Pagination"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import OrderItem from "./orderItem"
import OrderSearch from "./orderSearch"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  container: {},

  orderListBox: {
    width: "100%",
    marginTop: "4rem",
    padding: "1rem",
  },
  orderListGrid: { width: "100%" },

  outBox: {
    width: "100%",
    minHeight: "30rem",
    position: "relative",
  },
  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    color: theme.palette.common.blackNine,
    fontSize: "3rem !important",
  },
  noOrderText: {
    color: theme.palette.common.blackFourteen,
  },
  pageBox: {
    display: "flex",
    placeContent: "center",
  },
}))

const OrdersMainInCont = ({
  user,
  dispatchFeedback,
  kitchenSettings,
  storeSettings,
  setOrdersList,
  ordersList,
  page,
  requestNewPage,
  pageCount,
  setPage,
  setQString,
  loading,
  location,
  defaultQString,
  setSearchString,
  defPageSize,
  searchString,
}) => {
  const classes = useStyles()

  const handleChange = (event, value) => {
    requestNewPage(value)
  }

  return (
    <>
      <Box classes={{ root: classes.orderListBox }}>
        <Box className={classes.container}>
          <Box>
            <OrderSearch
              setQString={setQString}
              defaultQString={defaultQString}
              defPageSize={defPageSize}
              setSearchString={setSearchString}
              setPage={setPage}
              loading={loading}
              searchString={searchString}
            />
          </Box>
          {!loading ? (
            ordersList.map((orderItem, index) => {
              return (
                <OrderItem
                  key={index}
                  index={index}
                  orderItem={orderItem}
                  user={user}
                  dispatchFeedback={dispatchFeedback}
                  kitchenSettings={kitchenSettings}
                  storeSettings={storeSettings}
                  ordersList={ordersList}
                  setOrdersList={setOrdersList}
                  location={location}
                />
              )
            })
          ) : (
            <></>
          )}
          {ordersList.length === 0 || loading ? (
            <Box classes={{ root: classes.outBox }}>
              <Box classes={{ root: classes.inBox }}>
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <>
                    <ErrorOutlineOutlinedIcon
                      classes={{ root: classes.icon }}
                    ></ErrorOutlineOutlinedIcon>
                    <Typography
                      variant="body2"
                      classes={{ root: classes.noOrderText }}
                    >{`No Orders Found`}</Typography>
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        {!loading ? (
          <Box classes={{ root: classes.pageBox }}>
            <Pagination
              disabled={loading}
              count={pageCount}
              page={page}
              shape={"rounded"}
              onChange={handleChange}
              size={"small"}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  )
}

export default OrdersMainInCont
