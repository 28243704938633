import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"
import InputBase from "@material-ui/core/InputBase"
import InputAdornment from "@material-ui/core/InputAdornment"

import priceFormat from "../../../helperFunc/priceFormat"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  textContainer: {
    paddingRight: "1.5rem",
  },

  itemHeading: {
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
  },

  itemHeadingBold: {
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    fontWeight: 600,
  },
  price: { fontSize: "0.9rem", lineHeight: "1.1rem" },
  priceBold: { fontSize: "0.9rem", lineHeight: "1.1rem", fontWeight: 600 },
  priceBoldCrossed: {
    fontSize: "0.9rem",
    lineHeight: "1.1rem",
    textDecoration: "line-through",
    marginRight: "0.75rem",
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2px",
  },

  priceGrid: {
    display: "flex",
    justifyContent: "right",
  },

  mainBox: {
    backgroundColor: theme.palette.common.blackTwo,
    padding: "0.5rem",
    borderRadius: "1rem",
    maxWidth: "6.5rem",
    marginTop: "0.3rem",
    marginBottom: "0.4rem",
  },

  refundTextBox: {
    backgroundColor: theme.palette.common.blackThree,
    display: "flex",
    alignItems: "baseline",
    borderRadius: "1rem",
    marginBottom: "0.5rem",
    padding: "0.25rem",
  },

  refundText: {
    padding: 0,
    fontWeight: 600,
    textAlign: "left",

    fontSize: "1rem",
    lineHeight: "1rem",
  },
  refundDoller: {
    marginLeft: "0.25rem",
  },

  remainingText: {
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    marginLeft: "0.5rem",
    textAlign: "left",
    color: theme.palette.common.blackTweleve,
  },
}))

const CartServiceItem = ({
  orderItem,
  refundServiceFee,
  refundPhSurchargeFee,
  refundSatSurchargeFee,
  refundSunSurchargeFee,
  setRefundServiceFee,
  setRefundPhSurchargeFee,
  setRefundSatSurchargeFee,
  setRefundSunSurchargeFee,
  isLoading,
  isPartRefund,
  availableRefundServiceFee,
  availableRefundPhSurchargeFee,
  availableRefundSatSurchargeFee,
  availableRefundSunSurchargeFee,
}) => {
  const classes = useStyles()

  const handleChange = (e, setRefundFunc, availableRefund, refundFee) => {
    if (
      e.nativeEvent.data !== "-" &&
      e.nativeEvent.data !== "+" &&
      e.nativeEvent.data !== "."
    ) {
      let valueIn = e.target.value

      if (isNaN(valueIn) || isNaN(parseFloat(valueIn))) {
        if (valueIn === "") {
          setRefundFunc("")
        }
      } else {
        let value = parseFloat(valueIn)
        let newRefundAmm = parseFloat(value.toFixed(2))
        if (newRefundAmm > availableRefund) {
          newRefundAmm = availableRefund
        }
        if (newRefundAmm < 0) {
          setRefundFunc("")
        } else {
          let lastTwo = valueIn.slice(-2)
          if (valueIn === "0.0" || valueIn === ".0" || lastTwo === ".0") {
            setRefundFunc(valueIn)
          } else {
            setRefundFunc(newRefundAmm)
          }
        }
      }
    } else if (e.nativeEvent.data === ".") {
      let ammountStr = `${refundFee}`
      if (ammountStr.slice(ammountStr.length - 1) !== ".") {
        setRefundFunc(`${refundFee}.`)
      }
    }
  }

  const handleKeyDown = e => {
    if (
      ((e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8 ||
        e.keyCode === 110 ||
        e.keyCode === 46 ||
        e.keyCode === 190) &&
      e.key !== "-"
    ) {
    } else {
      e.preventDefault()
    }
  }

  return (
    <>
      <Divider classes={{ root: classes.divider }} />
      <Grid item>
        {orderItem.attributes.serviceFee > 0 ||
        orderItem.attributes.phSurchargeFee > 0 ||
        orderItem.attributes.sunSurchargeFee > 0 ||
        orderItem.attributes.satSurchargeFee > 0 ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              classes={{ root: classes.mainContainer }}
            >
              <Grid item classes={{ root: classes.textContainer }}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.itemHeading }}
                >
                  {"Subtotal"}
                </Typography>
              </Grid>
              <Grid item classes={{ root: classes.priceGrid }}>
                {orderItem.attributes.refundSubtotal > 0 ? (
                  <Typography
                    variant="body1"
                    classes={{ root: classes.priceBoldCrossed }}
                  >
                    {priceFormat(orderItem.attributes.subtotal, null, true)}
                  </Typography>
                ) : (
                  <></>
                )}

                <Typography variant="body1" classes={{ root: classes.price }}>
                  {priceFormat(
                    parseFloat(
                      (
                        orderItem.attributes.subtotal -
                        orderItem.attributes.refundSubtotal
                      ).toFixed(2)
                    ),
                    null,
                    true
                  )}
                </Typography>
              </Grid>
            </Grid>

            {orderItem.attributes.serviceFee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Service Fee"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box classes={{ root: classes.priceGrid }}>
                    {orderItem.attributes.refundServiceFee > 0 ? (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.priceBoldCrossed }}
                      >
                        {priceFormat(
                          orderItem.attributes.serviceFee,
                          null,
                          true
                        )}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Typography
                      variant="body1"
                      classes={{ root: classes.price }}
                    >
                      {priceFormat(
                        parseFloat(
                          (
                            orderItem.attributes.serviceFee -
                            orderItem.attributes.refundServiceFee
                          ).toFixed(2)
                        ),
                        null,
                        true
                      )}
                    </Typography>
                  </Box>

                  {isPartRefund && availableRefundServiceFee > 0 ? (
                    <Box classes={{ root: classes.mainBox }}>
                      <Box classes={{ root: classes.refundTextBox }}>
                        <InputBase
                          classes={{ input: classes.refundText }}
                          value={refundServiceFee}
                          inputProps={{
                            min: 0,
                            inputMode: "decimal",
                            pattern: "^d*(.d{0,2})?$",
                          }}
                          placeholder={"0.00"}
                          readOnly={isLoading}
                          onChange={e => {
                            handleChange(
                              e,
                              setRefundServiceFee,
                              availableRefundServiceFee,
                              refundServiceFee
                            )
                          }}
                          onKeyDown={handleKeyDown}
                          startAdornment={
                            <InputAdornment
                              position="start"
                              classes={{ root: classes.refundDoller }}
                            >
                              $
                            </InputAdornment>
                          }
                          fullWidth
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.remainingText }}
                      >
                        {`${priceFormat(
                          availableRefundServiceFee,
                          null,
                          true
                        )} Avl.`}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {orderItem.attributes.phSurchargeFee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Holiday Surcharge"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box classes={{ root: classes.priceGrid }}>
                    {orderItem.attributes.refundPhSurchargeFee > 0 ? (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.priceBoldCrossed }}
                      >
                        {priceFormat(
                          orderItem.attributes.phSurchargeFee,
                          null,
                          true
                        )}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Typography
                      variant="body1"
                      classes={{ root: classes.price }}
                    >
                      {priceFormat(
                        parseFloat(
                          (
                            orderItem.attributes.phSurchargeFee -
                            orderItem.attributes.refundPhSurchargeFee
                          ).toFixed(2)
                        ),
                        null,
                        true
                      )}
                    </Typography>
                  </Box>

                  {isPartRefund && availableRefundPhSurchargeFee > 0 ? (
                    <Box classes={{ root: classes.mainBox }}>
                      <Box classes={{ root: classes.refundTextBox }}>
                        <InputBase
                          classes={{ input: classes.refundText }}
                          value={refundPhSurchargeFee}
                          inputProps={{
                            min: 0,
                            inputMode: "decimal",
                            pattern: "^d*(.d{0,2})?$",
                          }}
                          placeholder={"0.00"}
                          readOnly={isLoading}
                          onChange={e => {
                            handleChange(
                              e,
                              setRefundPhSurchargeFee,
                              availableRefundPhSurchargeFee,
                              refundPhSurchargeFee
                            )
                          }}
                          onKeyDown={handleKeyDown}
                          startAdornment={
                            <InputAdornment
                              position="start"
                              classes={{ root: classes.refundDoller }}
                            >
                              $
                            </InputAdornment>
                          }
                          fullWidth
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.remainingText }}
                      >
                        {`${priceFormat(
                          availableRefundPhSurchargeFee,
                          null,
                          true
                        )} Avl.`}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {orderItem.attributes.sunSurchargeFee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Weekend Surcharge"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box classes={{ root: classes.priceGrid }}>
                    {orderItem.attributes.refundSunSurchargeFee > 0 ? (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.priceBoldCrossed }}
                      >
                        {priceFormat(
                          orderItem.attributes.sunSurchargeFee,
                          null,
                          true
                        )}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Typography
                      variant="body1"
                      classes={{ root: classes.price }}
                    >
                      {priceFormat(
                        parseFloat(
                          (
                            orderItem.attributes.sunSurchargeFee -
                            orderItem.attributes.refundSunSurchargeFee
                          ).toFixed(2)
                        ),
                        null,
                        true
                      )}
                    </Typography>
                  </Box>

                  {isPartRefund && availableRefundSunSurchargeFee > 0 ? (
                    <Box classes={{ root: classes.mainBox }}>
                      <Box classes={{ root: classes.refundTextBox }}>
                        <InputBase
                          classes={{ input: classes.refundText }}
                          value={refundSunSurchargeFee}
                          inputProps={{
                            min: 0,
                            inputMode: "decimal",
                            pattern: "^d*(.d{0,2})?$",
                          }}
                          placeholder={"0.00"}
                          readOnly={isLoading}
                          onChange={e => {
                            handleChange(
                              e,
                              setRefundSunSurchargeFee,
                              availableRefundSunSurchargeFee,
                              refundSunSurchargeFee
                            )
                          }}
                          onKeyDown={handleKeyDown}
                          startAdornment={
                            <InputAdornment
                              position="start"
                              classes={{ root: classes.refundDoller }}
                            >
                              $
                            </InputAdornment>
                          }
                          fullWidth
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.remainingText }}
                      >
                        {`${priceFormat(
                          availableRefundSunSurchargeFee,
                          null,
                          true
                        )} Avl.`}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {orderItem.attributes.satSurchargeFee > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                classes={{ root: classes.mainContainer }}
              >
                <Grid item classes={{ root: classes.textContainer }}>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.itemHeading }}
                  >
                    {"Weekend Surcharge"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box classes={{ root: classes.priceGrid }}>
                    {orderItem.attributes.refundSatSurchargeFee > 0 ? (
                      <Typography
                        variant="body1"
                        classes={{ root: classes.priceBoldCrossed }}
                      >
                        {priceFormat(
                          orderItem.attributes.satSurchargeFee,
                          null,
                          true
                        )}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <Typography
                      variant="body1"
                      classes={{ root: classes.price }}
                    >
                      {priceFormat(
                        parseFloat(
                          (
                            orderItem.attributes.satSurchargeFee -
                            orderItem.attributes.refundSatSurchargeFee
                          ).toFixed(2)
                        ),
                        null,
                        true
                      )}
                    </Typography>
                  </Box>

                  {isPartRefund && availableRefundSatSurchargeFee > 0 ? (
                    <Box classes={{ root: classes.mainBox }}>
                      <Box classes={{ root: classes.refundTextBox }}>
                        <InputBase
                          classes={{ input: classes.refundText }}
                          value={refundSatSurchargeFee}
                          inputProps={{
                            min: 0,
                            inputMode: "decimal",
                            pattern: "^d*(.d{0,2})?$",
                          }}
                          placeholder={"0.00"}
                          readOnly={isLoading}
                          onChange={e => {
                            handleChange(
                              e,
                              setRefundSatSurchargeFee,
                              availableRefundSatSurchargeFee,
                              refundSatSurchargeFee
                            )
                          }}
                          onKeyDown={handleKeyDown}
                          startAdornment={
                            <InputAdornment
                              position="start"
                              classes={{ root: classes.refundDoller }}
                            >
                              $
                            </InputAdornment>
                          }
                          fullWidth
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.remainingText }}
                      >
                        {`${priceFormat(
                          availableRefundSatSurchargeFee,
                          null,
                          true
                        )} Avl.`}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {orderItem.attributes.refundAmount > 0 ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            classes={{ root: classes.mainContainer }}
          >
            <Grid item classes={{ root: classes.textContainer }}>
              <Typography
                variant="body1"
                classes={{ root: classes.itemHeading }}
              >
                {"Refund"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" classes={{ root: classes.price }}>
                {`- ${priceFormat(
                  orderItem.attributes.refundAmount,
                  null,
                  true
                )}`}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          classes={{ root: classes.mainContainer }}
        >
          <Grid item classes={{ root: classes.textContainer }}>
            <Typography
              variant="body1"
              classes={{ root: classes.itemHeadingBold }}
            >
              {"Total"}
            </Typography>
          </Grid>
          <Grid item classes={{ root: classes.priceGrid }}>
            {orderItem.attributes.refundAmount > 0 ? (
              <Typography
                variant="body1"
                classes={{ root: classes.priceBoldCrossed }}
              >
                {priceFormat(
                  parseFloat(
                    (
                      orderItem.attributes.subtotal +
                      orderItem.attributes.serviceFee +
                      orderItem.attributes.phSurchargeFee +
                      orderItem.attributes.satSurchargeFee +
                      orderItem.attributes.sunSurchargeFee
                    ).toFixed(2)
                  ),
                  null,
                  true
                )}
              </Typography>
            ) : (
              <></>
            )}

            <Typography variant="body1" classes={{ root: classes.priceBold }}>
              {priceFormat(
                parseFloat(
                  (
                    orderItem.attributes.subtotal +
                    orderItem.attributes.serviceFee +
                    orderItem.attributes.phSurchargeFee +
                    orderItem.attributes.satSurchargeFee +
                    orderItem.attributes.sunSurchargeFee -
                    orderItem.attributes.refundAmount
                  ).toFixed(2)
                ),
                null,
                true
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CartServiceItem
