const getHrMinText = timeIn => {
  let textToSend = ""
  let days = Math.floor(timeIn / (60 * 24))
  let hours = Math.floor(timeIn / 60) - days * 24
  let minutes = timeIn - hours * 60 - days * 24 * 60

  if (hours < 12) {
    textToSend = `${hours >= 10 ? `${hours}` : `0${hours}`}:${
      minutes >= 10 ? `${minutes}` : `0${minutes}`
    } AM`
  } else {
    if (hours >= 13) {
      hours = hours - 12
    }
    textToSend = `${hours >= 10 ? `${hours}` : `0${hours}`}:${
      minutes >= 10 ? `${minutes}` : `0${minutes}`
    } PM`
  }

  return textToSend
}

export default getHrMinText
