import React, { useState } from "react"
import clsx from "clsx"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"
import CssBaseline from "@material-ui/core/CssBaseline"
import useMediaQuery from "@material-ui/core/useMediaQuery"

//import ListItem from "@material-ui/core/ListItem"
//import ListItemText from "@material-ui/core/ListItemText"

import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"

//import Logo from "../../images/LogoWhite.svg"

import LogoutIcon from "@mui/icons-material/Logout"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined"

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  menuIconColour: {
    color: theme.palette.common.white,
  },
  menuIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: theme.palette.common.white,
  },

  menuIconHeader: {
    fontSize: "2rem !important",
    marginRight: "0.5rem",
  },

  ListItemText: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },

  logo: {
    height: 50,
    marginTop: "0.1rem",
    marginBottom: "0.1rem",
  },

  logoButton: {
    marginLeft: 0,
    marginRight: "auto",
  },

  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
  },

  mainBox: {
    display: "flex",
  },

  navBox: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  mobDrawer: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },

    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  },

  mainDrawer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },

    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  },

  mainContBox: {
    flexGrow: 1,
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  drawerPaper: {
    minWidth: drawerWidth,
    backgroundColor: theme.palette.common.blackTwo,
    border: 0,
  },
  listItem: {
    color: theme.palette.common.almostWhite,
    backgroundColor: theme.palette.common.blackTwo,
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
  },

  listItemSelected: {
    backgroundColor: theme.palette.common.black,
  },

  menuHeadingContainer: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
    paddingTop: "1rem",
    paddingLeft: "0.8rem",
    paddingRight: "1rem",
    [theme.breakpoints.up("sm")]: {
      marginTop: "3rem",
    },
  },

  "@global": {
    ".stripped": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}))

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export default function AdminSystemAdminHeader(
  { children, page, user, isUserAut },
  props
) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)
  const matchesMD = useMediaQuery(theme => theme.breakpoints.down("xs"))

  const handleDrawerToggle = () => {
    if (matchesMD) {
      setMobileOpen(!mobileOpen)
    }
  }

  const classes = useStyles()
  let data = useStaticQuery(graphql`
    query getAdminLogo {
      allStrapiStoreInfo {
        edges {
          node {
            logo {
              url
            }
          }
        }
      }
    }
  `)
  let logoUrl = data.allStrapiStoreInfo.edges[0].node.logo[1].url

  //const routes = [...categories, { attributes: { name: "test" } }]
  //{ icon: <SearchOutlinedIcon />, ariaLabel: "search" },
  const actions = [
    {
      icon: <LogoutIcon />,
      ariaLabel: "logOut",
      link: "/orders/admin/log-out",
    },
  ]

  const listLinks = [
    {
      link: "/orders/admin",
      label: "Orders",
      page: "orders",
    },

    {
      link: "/orders/admin/stock",
      label: "Items Availability",
      page: "stock",
    },
    {
      link: "/orders/admin/store-settings",
      label: "Store Settings",
      page: "storeSettings",
    },
    {
      link: "/orders/admin/kitchen-settings",
      label: "Kitchen Settings",
      page: "kitchenSettings",
    },
  ]

  const drawer = (
    <div>
      <Toolbar classes={{ root: classes.menuHeadingContainer }}>
        <AdminPanelSettingsOutlinedIcon
          classes={{ root: classes.menuIconHeader }}
        ></AdminPanelSettingsOutlinedIcon>
        <Typography variant="h6">{"Admin Panel"}</Typography>
      </Toolbar>

      <List disablePadding classes={{ root: classes.menContainer }}>
        {listLinks.map((list, index) => (
          <Link to={`${list.link}`} className="stripped" key={index}>
            <ListItem
              divider
              button
              classes={{
                root: clsx(classes.listItem, {
                  [classes.listItemSelected]: page === `${list.page}`,
                }),
              }}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    classes={{ root: classes.ListItemText }}
                  >
                    {`${list.label}`}
                  </Typography>
                }
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box classes={{ root: classes.mainBox }}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar elevation={0} classes={{ root: classes.appBar }}>
          <Toolbar>
            <IconButton
              onClick={handleDrawerToggle}
              classes={{ root: classes.menuIcon }}
              aria-label="menu"
            >
              <MenuOutlinedIcon />
            </IconButton>
            <Button
              classes={{ root: classes.logoButton }}
              component={Link}
              to="/orders/admin"
            >
              <Box
                classes={{ root: classes.logo }}
                component="img"
                alt="Your logo."
                src={`${logoUrl}`}
              />
            </Button>
            {isUserAut ? (
              <Typography
                variant="body2"
                classes={{ root: classes.menuIconColour }}
              >
                {`${user.userDisplayName}`}
              </Typography>
            ) : (
              <></>
            )}

            {isUserAut ? (
              actions.map((action, index) => (
                <IconButton
                  classes={{ root: classes.menuIconColour }}
                  aria-label={`${action.ariaLabel}`}
                  key={index}
                  component={action.link ? Link : undefined}
                  to={action.link ? action.link : undefined}
                  state={action.state ? action.state : undefined}
                  replace={action.replace ? action.replace : undefined}
                >
                  {action.icon}
                </IconButton>
              ))
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Box
        component="nav"
        classes={{ root: classes.navBox }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          classes={{ root: classes.mobDrawer, paper: classes.drawerPaper }}
        >
          {isUserAut ? drawer : <></>}
        </Drawer>
        <Drawer
          variant="permanent"
          classes={{ root: classes.mainDrawer, paper: classes.drawerPaper }}
          open
        >
          {isUserAut ? drawer : <></>}
        </Drawer>
      </Box>

      <Box component="main" classes={{ root: classes.mainContBox }}>
        {children}
      </Box>
    </Box>
  )
}
