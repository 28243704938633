import * as React from "react"
import clsx from "clsx"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import CircularProgress from "@material-ui/core/CircularProgress"

import FlatwareOutlinedIcon from "@mui/icons-material/FlatwareOutlined"
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined"
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined"
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined"
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined"
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined"
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined"
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined"
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"

import getHrMinText from "../helperFunc/getHrMinText"
import priceFormat from "../../../helperFunc/priceFormat"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderItemBox: {
    marginBottom: "1rem",
    borderRadius: "0.75rem",
    padding: "1rem",
    textAlign: "left",
    backgroundColor: theme.palette.common.blackTwo,
  },
  itemGrid: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },

  popper: {
    margin: "0 0.35rem !important",
  },

  greyText: {
    color: theme.palette.common.blackNine,
    marginRight: "0.25rem",
  },

  boldText: {
    lineHeight: "1rem",
    fontWeight: 500,
    overflowWrap: "anywhere",
  },

  boldTextTitle: {
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
    overflowWrap: "break-word",
    marginBottom: "0.25rem",
  },

  ordTypeAva: {
    width: "2rem !important",
    height: "2rem !important",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
  },

  ordStatus: {
    width: "2rem !important",
    height: "2rem !important",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
  },
  ordStatusBut: {
    width: "2rem !important",
    height: "2rem !important",
    marginRight: "0.5rem",
    cursor: "pointer",
    color: theme.palette.common.white,
  },

  dineInAva: {
    backgroundColor: theme.palette.common.dineIn,
  },
  takeAwayAva: { backgroundColor: theme.palette.common.blackFive },

  failedAva: {
    backgroundColor: theme.palette.common.red,
  },
  receivedAva: {
    backgroundColor: theme.palette.common.received,
  },
  preparingAva: {
    backgroundColor: theme.palette.common.preparing,
  },
  readyAva: {
    backgroundColor: theme.palette.common.ready,
  },
  doneAva: {
    backgroundColor: theme.palette.common.done,
  },

  textBox: {
    display: "flex",
    alignItems: "center",
    paddingRight: "0.25rem",
  },

  detailsBox: {
    marginBottom: "0.25rem",
  },

  statusBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    display: "flex",
    alignItems: "center",
  },

  typeBox: {
    paddingTop: "0.15rem",
    paddingBottom: "0.15rem",
    display: "flex",
    alignItems: "center",
  },
  totalBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },
  refundBox: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    [theme.breakpoints.up("sm")]: {
      marginBottom: ({ state }) => (state !== "Failed" ? "1.5rem" : 0),
    },
  },
  chipTime: {
    height: "1.75rem",
    backgroundColor: theme.palette.common.black,
  },
  chipPrice: {
    height: "1.75rem",
    backgroundColor: theme.palette.common.blackThree,
  },
  stateText: {
    lineHeight: "1rem",
  },

  iconSize: {
    fontSize: "1.25rem !important",
  },

  iconSizeSucess: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.green,
  },

  iconSizeFail: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.red,
  },

  changeStateText: {
    lineHeight: "1rem",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "0.75rem",
      fontSize: "0.75rem",
    },
  },

  blockBut: {
    lineHeight: "1rem !important",
    borderRadius: "6rem",
    textAlign: "left !important",
    minHeight: "2rem !important",
    borderWidth: "1px",
    borderColor: theme.palette.common.blackNine,
  },

  butText: {
    overflowWrap: "break-word",
  },
}))

const OrderDetails = ({
  orderItem,
  user,
  dispatchFeedback,
  ordersList,
  setOrdersList,
  index,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const classes = useStyles({ state: orderItem.attributes.status })

  const handleChangeState = toState => {
    setIsLoading(true)
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/kitchen-setting/set-ord-status",
        {
          id: orderItem.id,
          newStatus: toState,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        axios
          .get(
            process.env.GATSBY_STRAPI_URL +
              `/api/orders/${orderItem.id}?populate[0]=users_permissions_user`,
            {
              headers: {
                Authorization: `Bearer ${user.jwt}`,
              },
            }
          )
          .then(res => {
            setIsLoading(false)

            let ordersListCopy = [...ordersList]
            ordersListCopy[index] = res.data.data
            setOrdersList(ordersListCopy)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  const handleChangeBlock = () => {
    setIsLoading(true)
    axios
      .put(
        process.env.GATSBY_STRAPI_URL +
          `/api/users/${orderItem.attributes.users_permissions_user.data.id}`,
        {
          blocked:
            !orderItem.attributes.users_permissions_user.data.attributes
              .blocked,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        axios
          .get(
            process.env.GATSBY_STRAPI_URL +
              `/api/orders/${orderItem.id}?populate[0]=users_permissions_user`,
            {
              headers: {
                Authorization: `Bearer ${user.jwt}`,
              },
            }
          )
          .then(res => {
            setIsLoading(false)

            let ordersListCopy = [...ordersList]
            ordersListCopy[index] = res.data.data
            setOrdersList(ordersListCopy)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        handleError(error, dispatchFeedback)
      })
  }

  return (
    <Box classes={{ root: classes.orderItemBox }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={8} sm={12} md={12} lg={12} xl={12}>
          <Box classes={{ root: classes.detailsBox }}>
            <Box classes={{ root: classes.textBox }}>
              <Typography
                variant="body1"
                classes={{ root: classes.boldText }}
              >{`${orderItem.attributes.users_permissions_user.data.attributes.userDisplayName}`}</Typography>
            </Box>

            <Box classes={{ root: classes.textBox }}>
              <Typography
                variant="body1"
                classes={{ root: classes.greyText }}
              >{`M: `}</Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.boldText }}
              >{`${orderItem.attributes.users_permissions_user.data.attributes.phone}`}</Typography>
            </Box>

            <Box classes={{ root: classes.textBox }}>
              <Typography
                variant="body1"
                classes={{ root: classes.greyText }}
              >{`E: `}</Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.boldText }}
              >{`${orderItem.attributes.users_permissions_user.data.attributes.receiptEmail}`}</Typography>
            </Box>
          </Box>

          <Box classes={{ root: classes.statusBox }}>
            <Avatar
              classes={{
                root: clsx(classes.ordStatus, {
                  [classes.failedAva]: orderItem.attributes.status === "Failed",
                  [classes.receivedAva]:
                    orderItem.attributes.status === "Received",
                  [classes.preparingAva]:
                    orderItem.attributes.status === "Preparing",
                  [classes.readyAva]: orderItem.attributes.status === "Ready",
                  [classes.doneAva]: orderItem.attributes.status === "Done",
                }),
              }}
            >
              {orderItem.attributes.status === "Failed" ? (
                <ErrorOutlineOutlinedIcon />
              ) : (
                <></>
              )}
              {orderItem.attributes.status === "Received" ? (
                <SettingsBackupRestoreOutlinedIcon />
              ) : (
                <></>
              )}
              {orderItem.attributes.status === "Preparing" ? (
                <WhatshotOutlinedIcon />
              ) : (
                <></>
              )}
              {orderItem.attributes.status === "Ready" ? (
                <DoneOutlinedIcon />
              ) : (
                <></>
              )}
              {orderItem.attributes.status === "Done" ? (
                <DoneAllOutlinedIcon />
              ) : (
                <></>
              )}
            </Avatar>

            <Typography variant="body1">{`${orderItem.attributes.status}`}</Typography>
          </Box>

          <Box classes={{ root: classes.typeBox }}>
            <Avatar
              classes={{
                root: clsx(classes.ordTypeAva, {
                  [classes.dineInAva]:
                    orderItem.attributes.orderType === "dineIn",
                  [classes.takeAwayAva]:
                    orderItem.attributes.orderType === "takeAway",
                }),
              }}
            >
              {orderItem.attributes.orderType === "dineIn" ? (
                <FlatwareOutlinedIcon />
              ) : (
                <></>
              )}
              {orderItem.attributes.orderType === "takeAway" ? (
                <TakeoutDiningOutlinedIcon />
              ) : (
                <></>
              )}
            </Avatar>

            <Typography
              variant="body1"
              classes={{ root: classes.stateText }}
            >{`${
              orderItem.attributes.orderType === "dineIn"
                ? `Table: ${orderItem.attributes.orderTableNumber}`
                : ""
            }${
              orderItem.attributes.orderType === "takeAway"
                ? `${getHrMinText(orderItem.attributes.orderPickUpTime)}, ${
                    orderItem.attributes.orderPickUpDate.label
                  }`
                : ""
            }`}</Typography>
          </Box>

          <Box classes={{ root: classes.statusBox }}>
            <Avatar
              classes={{
                root: clsx(classes.ordStatus, {
                  [classes.failedAva]:
                    orderItem.attributes.users_permissions_user.data.attributes
                      .blocked,
                  [classes.doneAva]:
                    !orderItem.attributes.users_permissions_user.data.attributes
                      .blocked,
                }),
              }}
            >
              {orderItem.attributes.users_permissions_user.data.attributes
                .blocked ? (
                isLoading ? (
                  <CircularProgress color="inherit" size={"1rem"} />
                ) : (
                  <BlockOutlinedIcon />
                )
              ) : isLoading ? (
                <CircularProgress color="inherit" size={"1rem"} />
              ) : (
                <LockOpenOutlinedIcon />
              )}
            </Avatar>

            <Button
              variant="outlined"
              disabled={isLoading}
              disableElevation
              endIcon={
                orderItem.attributes.users_permissions_user.data.attributes
                  .blocked ? (
                  <LockOpenOutlinedIcon />
                ) : (
                  <BlockOutlinedIcon />
                )
              }
              classes={{
                root: classes.blockBut,
                text: classes.butText,
              }}
              onClick={e => {
                handleChangeBlock()
              }}
            >
              {orderItem.attributes.users_permissions_user.data.attributes
                .blocked
                ? "UNBLOCK"
                : "BLOCK"}
            </Button>
          </Box>

          <Box classes={{ root: classes.totalBox }}>
            <Chip
              icon={
                orderItem.attributes.paymentStatus === "received" ? (
                  <CheckCircleOutlinedIcon
                    classes={{ root: classes.iconSizeSucess }}
                  />
                ) : orderItem.attributes.paymentStatus === "failed" ? (
                  <ErrorOutlineOutlinedIcon
                    classes={{ root: classes.iconSizeFail }}
                  />
                ) : orderItem.attributes.paymentStatus === "notReceived" ? (
                  <HighlightOffOutlinedIcon
                    classes={{ root: classes.iconSizeFail }}
                  />
                ) : orderItem.attributes.paymentStatus === "partRefunded" ? (
                  <RotateLeftOutlinedIcon
                    classes={{ root: classes.iconSize }}
                  />
                ) : orderItem.attributes.paymentStatus === "fullRefunded" ? (
                  <FlipCameraAndroidOutlinedIcon
                    classes={{ root: classes.iconSize }}
                  />
                ) : undefined
              }
              variant="outlined"
              label={`${priceFormat(orderItem.attributes.total)}`}
              classes={{ root: classes.chipPrice }}
            />
          </Box>

          <Box classes={{ root: classes.refundBox }}>
            <Chip
              icon={
                <CurrencyExchangeOutlinedIcon
                  classes={{ root: classes.iconSize }}
                />
              }
              variant="outlined"
              label={`${priceFormat(
                orderItem.attributes.refundAmount,
                null,
                true
              )}`}
              classes={{ root: classes.chipPrice }}
            />
          </Box>
        </Grid>
        {orderItem.attributes.status !== "Failed" ? (
          <Grid item xs={4} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="body1"
              classes={{ root: classes.boldTextTitle }}
            >
              {"CHANGE ORDER STATE"}
            </Typography>
            {orderItem.attributes.status !== "Received" ? (
              <Box classes={{ root: classes.statusBox }}>
                <Avatar
                  classes={{
                    root: clsx(classes.ordStatusBut, classes.receivedAva),
                  }}
                  onClick={e => {
                    handleChangeState("Received")
                  }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={"1rem"} />
                  ) : (
                    <SettingsBackupRestoreOutlinedIcon />
                  )}
                </Avatar>

                <Typography
                  variant="body1"
                  classes={{ root: classes.changeStateText }}
                >{`Mark as Received`}</Typography>
              </Box>
            ) : (
              <></>
            )}

            {orderItem.attributes.status !== "Preparing" ? (
              <Box classes={{ root: classes.statusBox }}>
                <Avatar
                  classes={{
                    root: clsx(classes.ordStatusBut, classes.preparingAva),
                  }}
                  onClick={e => {
                    handleChangeState("Preparing")
                  }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={"1rem"} />
                  ) : (
                    <WhatshotOutlinedIcon />
                  )}
                </Avatar>

                <Typography
                  variant="body1"
                  classes={{ root: classes.changeStateText }}
                >{`Mark as Preparing`}</Typography>
              </Box>
            ) : (
              <></>
            )}

            {orderItem.attributes.status !== "Ready" &&
            orderItem.attributes.orderType !== "dineIn" ? (
              <Box classes={{ root: classes.statusBox }}>
                <Avatar
                  classes={{
                    root: clsx(classes.ordStatusBut, classes.readyAva),
                  }}
                  onClick={e => {
                    handleChangeState("Ready")
                  }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={"1rem"} />
                  ) : (
                    <DoneOutlinedIcon />
                  )}
                </Avatar>

                <Typography
                  variant="body1"
                  classes={{ root: classes.changeStateText }}
                >{`Mark as Ready`}</Typography>
              </Box>
            ) : (
              <></>
            )}

            {orderItem.attributes.status !== "Done" ? (
              <Box classes={{ root: classes.statusBox }}>
                <Avatar
                  classes={{
                    root: clsx(classes.ordStatusBut, classes.doneAva),
                  }}
                  onClick={e => {
                    handleChangeState("Done")
                  }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={"1rem"} />
                  ) : (
                    <DoneAllOutlinedIcon />
                  )}
                </Avatar>

                <Typography
                  variant="body1"
                  classes={{ root: classes.changeStateText }}
                >{`Mark as Done`}</Typography>
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Box>
  )
}

export default OrderDetails
